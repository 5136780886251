@tailwind components;

@layer components {
  .nav-link {
    @apply font-libre font-normal text-neutral-500 decoration-primary-800 decoration-4 underline-offset-8 text-sm hover:text-primary-900 hover:underline;
  }
}

// CSS for The  Blog Page

@layer utilities {
  .blog {
    @apply para-sm font-raleway text-neutral-600;
    h1,
    h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6{
      @apply mb-4 mt-6;
    }

    section {
      @apply mb-2 mt-6 font-bold text-black text-base;
    }

    p {
      @apply mb-4;
    }

    a,
    strong {
      @apply font-semibold text-neutral-900;
    }
    a {
      @apply break-words underline;
    }
    strong {
      @apply para-base;
    }
    ul {
      @apply list-inside list-disc px-2;
    }
    li {
      @apply pb-2;
    }

    // SCSS for table coming from the CMS
    table {
      @apply my-8  w-full border-collapse border-2 text-left;
      td,
      th {
        @apply border px-2 py-2;
      }
    }
  }
}
