@tailwind components;
// @tailwind utilities;

@layer components {
  // Jsut Adding to so That we can update it laster when we need it
  .ac-cpm {
    @apply px-8 py-[3.75rem] sm:px-4 sm:py-[5rem] xl:px-10;
  }
  /* Heading Typography Styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply inline-block font-raleway font-semibold text-primary-800 underline decoration-secondary-500 underline-offset-8 sm:underline-offset-[10px] md:underline-offset-[11px] lg:underline-offset-[12px] xl:underline-offset-[13px];
  }

  h1,
  .h1 {
    @apply font-bold text-4xl;
  }

  h2,
  .h2 {
    @apply text-3xl;
  }

  h3,
  .h3 {
    @apply text-2xl;
  }

  h4,
  .h4 {
    @apply text-xl;
  }

  h5,
  .h5 {
    @apply text-lg;
  }

  h6,
  .h6 {
    @apply text-base;
  }

  .para-base {
    @apply font-raleway text-base;
  }
  .para-sm {
    @apply font-raleway text-sm;
  }
  .para-bold {
    @apply para-sm font-bold;
  }
  
  p {
    @apply font-raleway;
  }
}
