/* You can add global styles to this file, and also import other style files */
@import "theme";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .form-control {
    @apply mt-2 rounded-sm border border-gray-300 bg-white px-4 leading-6;

    .form-field-box-shadow {
      @apply shadow-form-input;
    }

    &:focus {
      @apply border-primary-900 shadow-form-input;
    }

    &:focus-visible {
      outline: none;
    }

    &.ng-invalid.ng-touched:not(:disabled):not([readonly]) {
      @apply border-form-border shadow-form-invalid;
    }

    &:disabled,
    &[readonly] {
      @apply rounded border-gray-400 bg-gray-300 text-black opacity-50 shadow-none;
    }
  }
}

@layer utilities {
  .scrollbar-hidden {
    scrollbar-width: none !important;
  }

  .scrollbar-hidden::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}