@tailwind components;

@layer components {
  .btn {
    @apply rounded px-9 py-2.5 lowercase first-letter:uppercase transition-all duration-300 ease-in-out focus:outline-none focus:ring-1 active:scale-90 md:w-auto w-full;

    &.secondary {
      @apply border border-secondary-500 bg-secondary-500 font-bold text-primary-900 hover:border-primary-900 hover:bg-primary-900 hover:text-white focus:ring-primary-400 active:bg-primary-800;

      &.disabled {
        @apply border-neutral-300 bg-neutral-300 text-white focus:ring-0 active:scale-100;
      }
    }
  }
}